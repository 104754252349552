<template>
  <div class="col-xl-12 footerbtn">
    <div v-if="isSubmit">
      <div v-if="!isEditing" class="creation-desktop-form-footer">
        <AppButton
          type="submit"
          title="Continue"
          className="primary"
          class="creation-form-footer__submit"
        />
      </div>

      <div
        v-if="!isEditing && !ishideMobileFooter"
        class="creation-mobile-form-footer"
      >
        <AppButton
          type="submit"
          title="Continue"
          className="primary"
          class="creation-form-footer__submit"
        />
      </div>
    </div>
    <div v-else>
      <div v-if="!isEditing" class="creation-desktop-form-footer">
        <AppButton
          title="Continue"
          className="primary"
          class="creation-form-footer__submit"
          @click.prevent="goNextStep"
        />
      </div>

      <div
        v-if="!isEditing && !ishideMobileFooter"
        class="creation-mobile-form-footer"
      >
        <AppButton
          title="Continue"
          className="primary"
          class="creation-form-footer__submit"
          @click.prevent="goNextStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "FooterButton",
  props: {
    isSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    ishideMobileFooter: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    goNextStep() {
      this.$emit("goNextStep");
    },

    onResize(e) {
      let windowWidth = window.innerWidth - 509;
      //let windowWidth = window.innerWidth - 526;
      if (windowWidth >= 100) {
        $(".creation-desktop-form-footer").width(windowWidth);
      }
    },
  },
};
</script>

<style lang="scss">
body {
  overflow-y: scroll !important;
}
</style>

<style scoped lang="scss">
@import "../../assets/scss/modules/form.scss";
</style>
