<template>
  <form
    ref="first-step-form"
    v-if="userData"
    class="creation-form"
    @submit.prevent="onSubmit"
  >
    <div class="row creation-form-row">
      <div class="creation-form__col col-xl-6">
        <AppField
          class="creation-form-field"
          label="First name"
          :value="userData.first_name"
          @input="setField($event, 'first_name')"
        >
        </AppField>
      </div>

      <div class="creation-form__col col-xl-6">
        <AppField
          class="account-form__field"
          label="Last name"
          :value="userData.last_name"
          @input="setField($event, 'last_name')"
        >
        </AppField>
      </div>

      <!-- <div class="creation-form__col col-xl-12">
        <AppField
          class="account-form__field"
          label="Street address"
          required
          :value="userData.street_address"
          :isInvalid="$v.userLocalData.street_address.$error"
          @input="setField($event, 'street_address')"
        >
          <template v-slot:error>
            <p v-if="!$v.userLocalData.street_address.required">
              This field is required
            </p>
          </template>
        </AppField>
      </div> -->

      <div class="creation-form__col col-xl-6">
        <AppField
          class="account-form__field"
          label="City"
          :value="userData.city"
          :placeHolder="geoCity"
          @input="setField($event, 'city')"
        >
        </AppField>
      </div>

      <div class="creation-form__col col-xl-3">
        <AppField
          class="account-form__field"
          :label="countryLabel"
          :value="userData.state"
          :placeHolder="geoState"
          @input="setField($event, 'state')"
        >
        </AppField>
      </div>

      <div class="creation-form__col col-xl-3">
        <AppField
          class="account-form__field"
          label="Zip code"
          :value="userData.zip_code"
          :placeHolder="geoZip"
          @input="setField($event, 'zip_code')"
        >
        </AppField>
      </div>

      <div class="creation-form__col col-xl-6">
        <AppField
          class="account-form__field"
          label="Enter your phone"
          type="tel"
          :value="userData.phone"
          :placeHolder="geoPhoneSample"
          @input="setField($event, 'phone')"
        >
        </AppField>
      </div>

      <div class="creation-form__col col-xl-6">
        <AppField
          class="account-form__field"
          label="Enter your e-mail"
          required
          :value="userData.email"
          :isInvalid="$v.userLocalData.email.$error"
          @input="setField($event, 'email')"
          placeHolder="peterwilliam@resume-perfect.com"
        >
          <template v-slot:error>
            <p v-if="!$v.userLocalData.email.required">
              This field is required
            </p>
            <p v-if="!$v.userLocalData.email.validateEmail">
              Enter a valid email address
            </p>
          </template>
        </AppField>
      </div>
    </div>

    <div class="row creation-form-row">
      <div class="creation-form__col col-xl-6">
        <div v-if="isAvailableUserPhoto && hasPhoto" class="creation-photo">
          <h3 class="creation-photo__title">Profile Picture</h3>

          <figure
            class="resume-photo"
            :class="{ 'resume-photo--filled': userData.photo }"
            @click.prevent="openModal"
          >
            <img
              v-if="userData.photo"
              :src="`${userData.photo}?v=${Date.now()}&token=${
                isLoggedIn ? accessToken : ''
              }`"
              alt=""
              class="resume-photo__img"
            />

            <div class="resume-photo__icon">
              <AppIcon componentName="UploadIcon" />
            </div>
          </figure>

          <p class="creation-photo__text">
            If your template has picture it will be shown.
          </p>
        </div>
      </div>
    </div>

    <div v-if="!isEditing" class="creation-form-header">
      <button
        class="creation-form-header__preview"
        type="button"
        @click.prevent="openSidebar"
      >
        <AppIcon componentName="SearchIcon" />
      </button>
    </div>

    <FooterButton
      v-if="!isShowBackButton"
      :isEditing="isEditing"
      isSubmit="true"
    ></FooterButton>
    <FooterButtonv2
      v-if="isShowBackButton"
      :isEditing="isEditing"
      isSubmit="true"
    ></FooterButtonv2>

    <AppModal
      :isOpenModal="isOpenUploadPhotoModal"
      width="600px"
      @close="closeModal"
      class="upload-photo-modal"
    >
      <UploadPhotoModal
        @cancel="closeModal"
        @confirmUploadedPhoto="uploadResumePhoto"
      />
    </AppModal>
  </form>
</template>

<script>
import { debounce } from "lodash/function";
import { email, required, numeric } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash/lang";

import { zipCodeValid } from "@/helpers/validators";
import { eventBus } from "@/helpers/event-bus";
import { LOCAL_COMPONENTS_NAMES } from "@/helpers/components-names";
import AccessToken from "@/helpers/access-token";
import ResumeHash from "@/helpers/resume-hash";
import EditingHash from "@/helpers/editing-hash";
import CookieHelper from "@/helpers/cookie-helper";

import UploadPhotoModal from "@/components/UploadUserPhotoModal";
import FooterButton from "@/components/creation/FooterButton";
import FooterButtonv2 from "@/components/creation/FooterButtonv2";
import $ from "jquery";

import {
  CREATING_RESUME_NAMES,
  CREATING_COVER_LETTER_NAMES,
} from "@/helpers/rout-constants";

export const firstStepFormProps = {
  isEditing: {
    type: Boolean,
    default: false,
  },
};

/* eslint-disable no-useless-escape */
const validateEmail = (value) => {
  if (value == null || value.trim() == "") {
    return true;
  }

  value = value.toLowerCase();
  if (
    /^[_a-z0-9-+]+(\.[_a-z0-9-+]+)*(\+[a-z0-9-]+)?\u0040[a-z0-9-.]+(\.[a-z0-9]+)$/.test(
      value
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export default {
  name: LOCAL_COMPONENTS_NAMES.FIRST_STEP_FORM,
  components: { UploadPhotoModal, FooterButton, FooterButtonv2 },
  props: {
    userData: {
      type: Object,
      required: true,
    },

    isAvailableUserPhoto: {
      type: Boolean,
      default: false,
    },
    ...firstStepFormProps,
  },
  validations: {
    userLocalData: {
      email: {
        required,
        validateEmail,
      },
    },
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),

    setFieldDebounced() {
      return debounce(function (data) {
        this.$emit("changeFilling", cloneDeep(this.userLocalData));

        this.$emit("input", data);
      }, 1000);
    },
  },

  data() {
    return {
      accessToken: AccessToken.get(),
      isOpenUploadPhotoModal: false,
      userLocalData: {
        first_name: null,
        last_name: null,
        city: null,
        state: null,
        zip_code: null,
        phone: null,
        email: null,
      },
      hasPhoto: false,
      showButton: true,
      geoCountryCode: "",
      geoCountry: "",
      geoCity: "",
      geoState: "",
      geoZip: "",
      geoPhoneSample: "",
      isShowBackButton: false,
      countryLabel: "State / Province",
    };
  },

  methods: {
    mobileFooter() {
      if ($(window).width() <= 991) {
        setTimeout(function () {
          $(".creation-mobile-form-footer").addClass("active");
        }, 2500);
      } else {
        $(".creation-mobile-form-footer").addClass("active");
      }
    },

    setInitialResumeValues(userData) {
      Object.keys(this.userLocalData).forEach((key) => {
        this.userLocalData[key] = userData[key];
      });

      this.$emit("changeFilling", cloneDeep(this.userLocalData));
    },

    setField(fieldData, fieldName) {
      this.userLocalData[fieldName] = fieldData;

      this.setFieldDebounced(this.userLocalData);
    },

    openModal() {
      this.isOpenUploadPhotoModal = true;
    },

    closeModal() {
      this.isOpenUploadPhotoModal = false;
    },

    setProfilePicture() {
      const template_ids = [5, 6, 26, 27, 28, 29, 30];

      this.hasPhoto = template_ids.some(
        (item) => item === this.userData.template_id
      );
    },

    async uploadResumePhoto(resumePhoto) {
      await this.$store.dispatch("loader/pending");

      try {
        const formData = new FormData();
        formData.append("photo", resumePhoto);
        formData.append(
          "hash",
          this.isEditing ? EditingHash.get() : ResumeHash.get()
        );

        await this.$store.dispatch(
          "resumeCreation/uploadResumePhoto",
          formData
        );

        this.$emit("input", this.userLocalData);

        this.userData.photo = `${this.userData.photo}?v=${Date.now()}&token=${
          this.isLoggedIn ? this.accessToken : ""
        }`;

        this.closeModal();
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    openSidebar() {
      eventBus.$emit("openCreationSidebar");
    },

    scrollIntoFirstError() {
      this.$nextTick(() => {
        const firstInvalidEl =
          this.$refs["first-step-form"].querySelector(".field--error");
        if (!firstInvalidEl) return;
        window.scroll({
          top:
            firstInvalidEl.getBoundingClientRect().top + window.scrollY - 100,
          behavior: "smooth",
        });
      });
    },

    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollIntoFirstError();
        return;
      }
      this.$emit("next", this.userLocalData);
    },

    checkValidationForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.scrollIntoFirstError();
      }

      return !this.$v.$invalid;
    },
  },
  mounted() {
    this.mobileFooter();
    document.addEventListener("mfooter", this.mobileFooter);

    let back_btn = CookieHelper.getFlag("back_btn", "");
    if (back_btn == "1") {
      this.isShowBackButton = true;
    }
  },
  created() {
    let vm = this;
    eventBus.$on("nextStepResumeCreation", this.onSubmit);
    eventBus.$on("changeTemplate", function () {
      vm.setProfilePicture();
    });

    this.setInitialResumeValues(this.userData);

    this.geoCountryCode = CookieHelper.getFlag("countrycode", "");
    this.geoCountry = CookieHelper.getFlag("country", "");
    this.geoCity = CookieHelper.getFlag("city", "");
    this.geoState = CookieHelper.getFlag("state", "");
    this.geoZip = CookieHelper.getFlag("zip", "");
    this.geoPhoneSample = CookieHelper.getFlag("phoneSample", "");

    if (this.geoCountryCode != "US") {
      this.countryLabel = "Country";
      this.geoState = this.geoCountry;
    }
    // if (
    //   (this.userData.city == "" || this.userData.city == null) &&
    //   city != ""
    // ) {
    //   this.setField(city, "city");
    //   this.userData.city = city;
    // }
    // if (
    //   (this.userData.state == "" || this.userData.state == null) &&
    //   state != ""
    // ) {
    //   this.setField(state, "state");
    //   this.userData.state = state;
    // }
    // if (
    //   (this.userData.zip_code == "" || this.userData.zip_code == null) &&
    //   zip != ""
    // ) {
    //   this.setField(zip, "zip_code");
    //   this.userData.zip_code = zip;
    // }
    this.setProfilePicture();

    switch (this.$route.name) {
      case CREATING_COVER_LETTER_NAMES.STEP_1:
        this.showButton = false;
        break;
      case CREATING_COVER_LETTER_NAMES.STEP_2:
        this.showButton = false;
        break;
      default:
        this.showButton = true;
        break;
    }
  },

  beforeDestroy() {
    document.removeEventListener("mfooter", this.mobileFooter);
    eventBus.$off("nextStepResumeCreation", this.onSubmit);
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/modules/form.scss";

.creation-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
}

.creation-photo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__title {
    width: 100%;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  &__text {
    font-size: 12px;
    color: $light-gray;
    flex: 1;
  }
}

.resume-photo {
  width: 90px;
  height: 90px;
  background-color: #fafafb;
  border-radius: 16px;
  margin-right: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fafafb;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: 1;
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: all 0.3s ease;

    svg {
      width: 32px;
      height: 32px;
      fill: $light-gray;
    }
  }

  &:hover {
    border-color: $primary;
    background-color: #fff;

    svg {
      fill: $primary;
    }
  }

  &--filled {
    .resume-photo__icon {
      background-color: rgba(#fff, 0.7);
      opacity: 0;
    }

    &:hover {
      .resume-photo__icon {
        opacity: 1;
      }
    }
  }
}

.form-footer-submit-initial button {
  width: 100%;
}

.creation-form-header__preview.order-md-2 {
  position: absolute;
  top: 0 !important;
  right: 9px;
  background: #fff;
  width: 70px;
  margin: 12px;
  padding: 2px;
  display: block;
  min-width: auto;
  z-index: 22;
  border: 2px solid #4874e6;
  border-radius: 30px;
}

button.creation-form-header__preview.order-md-2 svg {
  width: 17px;
  fill: #4874e6;
}

@media only screen and (max-width: 1329px) {
  .form-footer-submit-initial {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .creation-photo {
    margin-bottom: 15px;
  }
}
</style>
