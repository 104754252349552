<template>
  <div class="first-step-resume-editing">
    <FirstStepForm
      :userData="resume || coverLetter"
      :isEditing="isEditing"
      :isAvailableUserPhoto="isResume"
      @input="updatePreview"
      @changeFilling="changeFillingDegree"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import FirstStepForm, {
  firstStepFormProps,
} from "@/components/creation/FirstStepForm";
import {
  setIntermediateResume,
  setIntermediateLetter,
} from "@/helpers/save-intermediate-data";
import {
  COMPONENT_NAMES,
  LOCAL_COMPONENTS_NAMES,
} from "@/helpers/components-names";

export default {
  name: COMPONENT_NAMES.RESUME_CREATION.BASE_INFO_EDITING,
  components: {
    FirstStepForm,
  },

  props: {
    ...firstStepFormProps,
    isEditing: {
      type: Boolean,
      default: false,
    },
    isResume: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      resume: "resumeCreation/getResume",
      coverLetter: "coverLetterCreation/getCoverLetter",
    }),
  },

  data() {
    return {
      templateHash: null,
      openedEditingPart: null,
    };
  },

  methods: {
    changeFillingDegree(userLocalData) {
      this.$nextTick(() => {
        this.$parent.$emit("editData", userLocalData);
      });
    },

    async updatePreview(data) {
      if (!this.isResume) {
        await this.$store.dispatch(
          "coverLetterCreation/updateCoverLetter",
          data
        );

        await setIntermediateLetter(this.coverLetter.hash);
        return;
      }

      try {
        await this.$store.dispatch("resumeCreation/updateResume", data);
        await setIntermediateResume(this.resume.hash);
      } catch (e) {
        // console.error(e);
      }
    },

    checkValidation() {
      const firstStepForm = this.$children.find((component) => {
        return (
          component.$options.name === LOCAL_COMPONENTS_NAMES.FIRST_STEP_FORM
        );
      });

      return firstStepForm.checkValidationForm();
    },
  },

  // beforeDestroy() {
  //   window.removeEventListener("resize", this.windowResizeHandler);
  // },
};
</script>

<style lang="scss" scoped></style>
