<template>
  <div
    class="editing"
    :class="{
      'editing--opened': isOpened,
      'editing--error-validation': !isValidBlock,
    }"
  >
    <div class="editing-preview" @click="toggleEditing">
      <div class="editing-preview__arrow">
        <AppIcon componentName="ChevronIcon" />
      </div>

      <h3 class="editing-preview__title">{{ title }}</h3>

      <FilledType :fillingDegree="fillingDegree" />
    </div>

    <div ref="editingBody" class="editing-body">
      <p class="subtext">
        <slot name="subText" />
      </p>

      <slot />
    </div>
  </div>
</template>

<script>
import FilledType from "@/views/template-editing/components/FillingType";
import { COMPONENT_NAMES } from "@/helpers/components-names";

export default {
  name: COMPONENT_NAMES.EDITING_PART,
  components: { FilledType },

  props: {
    title: {
      type: String,
      default: "Editing part",
    },

    openedEditingPart: {
      type: String,
    },

    namesValidationComponents: {
      type: Array,
    },
  },

  computed: {
    isOpened() {
      return this.openedEditingPart === this.id;
    },
  },

  // watch: {
  //   isOpened(newVal) {
  //     // this.slideToggle(newVal);
  //   },
  // },

  data() {
    return {
      id: this.$uuid.v4(),
      templateHash: null,
      fillingDegree: 0,
      originalHeight: 0,
      isValidBlock: true,
    };
  },

  methods: {
    toggleEditing() {
      this.$emit("toggle", { opened: this.isOpened, id: this.id });
    },

    closeEditing() {
      this.$emit("close");
    },

    checkFillingDegree(data) {
      if (!data) {
        this.fillingDegree = 0;
        return;
      }

      if (typeof data === "string") {
        if (!data.length) {
          this.fillingDegree = 0;
          return;
        }

        this.fillingDegree = 2;

        return;
      }

      if (typeof data === "number") {
        if (data === 0) {
          this.fillingDegree = 0;
          return;
        }

        this.fillingDegree = 2;

        return;
      }

      const dataObjectKeys = Object.values(data);

      if (!dataObjectKeys.length) {
        this.fillingDegree = 0;
        return;
      }

      const filledFields = dataObjectKeys.filter(Boolean);

      if (filledFields.length === dataObjectKeys.length) {
        this.fillingDegree = 2;
        return;
      }

      this.fillingDegree = 1;
    },

    //TODO: maybe need to change this to without animation styles, or to just "max-height"

    /**
     * Method for slide toggle height editing body
     * @param isOpen {Boolean}}
     */
    slideToggle(isOpen) {
      let originalHeight = 0;

      if (!isOpen) {
        originalHeight = this.$refs.editingBody.getBoundingClientRect().height;
        this.$refs.editingBody.style.overflow = "hidden";
        this.$refs.editingBody.style.height = `${originalHeight}px`;

        setTimeout(() => {
          this.$refs.editingBody.style.height = "0";
        }, 0);

        return;
      }

      this.$refs.editingBody.style.position = "absolute";
      this.$refs.editingBody.style.cssText = `
        position: absolute;
        visibility: hidden;
        left: -9999px;
        height: auto;
      `;

      originalHeight =
        this.$refs.editingBody.getBoundingClientRect().height + 24;

      this.$refs.editingBody.style.cssText = "";

      setTimeout(() => {
        this.$refs.editingBody.style.overflow = "hidden";
        this.$refs.editingBody.style.height = `${originalHeight}px`;
      }, 0);

      setTimeout(() => {
        this.$refs.editingBody.style.overflow = "visible";
        this.$refs.editingBody.style.height = "auto";
      }, 500);
    },

    validateEditingSteps() {
      const component = this.$children.find((includedComponent) => {
        return this.namesValidationComponents.includes(
          includedComponent.$options.name
        );
      });

      this.isValidBlock = component.checkValidation();

      return this.isValidBlock;
    },
  },

  mounted() {
    this.$on("editData", this.checkFillingDegree);
  },
};
</script>

<style lang="scss" scoped>
.editing {
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 16px;
  overflow: hidden;
  padding: 0 24px;
  transition: all 0.5s ease;
  width: 100%;
}

.editing-preview {
  display: flex;
  align-items: center;
  padding: 24px 0;
  cursor: pointer;
  user-select: none;

  &__arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    svg {
      width: 13px;
      height: 8px;
      transform: rotate(-90deg);
      transition: transform 0.5s ease;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    transition: color 0.5s ease;
  }
}

.editing-body {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease;
}

.editing--opened {
  border-color: $primary;

  .editing-preview {
    &__title {
      color: $primary;
    }

    &__arrow {
      svg {
        transform: none;
        fill: $primary;
      }
    }
  }

  .editing-body {
    max-height: 2200px;
    padding-bottom: 24px;
    overflow: visible;
  }
}

.subtext {
  margin-bottom: 48px;
  font-size: 14px;
  line-height: 130%;
  color: $light-gray;
}

.editing--error-validation {
  border-color: $red;

  .editing-preview {
    &__arrow {
      svg {
        fill: $red;
      }
    }

    &__title {
      color: $red;
    }
  }
}
</style>
