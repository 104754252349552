<template>
  <div class="col-xl-12 footerbtn">
    <div v-if="isSubmit">
      <div v-if="!isEditing" class="creation-desktop-form-footer">
        <AppButton
          type="button"
          title="Back"
          class="back-btn"
          @click.prevent="goBack"
        />

        <AppButton
          type="submit"
          title="Continue"
          className="primary"
          class="nxt-btn"
        />
        <div class="clear"></div>
      </div>

      <div
        v-if="!isEditing && !ishideMobileFooter"
        class="creation-mobile-form-footer"
      >
        <AppButton
          type="button"
          title="Back"
          class="back-btn-mobile"
          @click.prevent="goBack"
        />

        <AppButton
          type="submit"
          title="Continue"
          className="primary"
          class="nxt-btn-mobile"
        />
        <div class="clear"></div>
      </div>
    </div>
    <div v-else>
      <div v-if="!isEditing" class="creation-desktop-form-footer">
        <AppButton
          type="button"
          title="Back"
          class="back-btn"
          @click.prevent="goBack"
        />

        <AppButton
          title="Continue"
          className="primary"
          class="nxt-btn"
          @click.prevent="goNextStep"
        />
        <div class="clear"></div>
      </div>

      <div
        v-if="!isEditing && !ishideMobileFooter"
        class="creation-mobile-form-footer"
      >
        <AppButton
          type="button"
          title="Back"
          class="back-btn-mobile"
          @click.prevent="goBack"
        />

        <AppButton
          title="Continue"
          className="primary"
          class="nxt-btn-mobile"
          @click.prevent="goNextStep"
        />
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "FooterButton",
  props: {
    isSubmit: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    ishideMobileFooter: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    goNextStep() {
      this.$emit("goNextStep");
    },

    goBack() {
      history.back();
    },

    onResize(e) {
      let windowWidth = window.innerWidth - 509;
      //let windowWidth = window.innerWidth - 526;
      if (windowWidth >= 100) {
        $(".creation-desktop-form-footer").width(windowWidth);
      }
    },
  },
};
</script>

<style lang="scss">
body {
  overflow-y: scroll !important;
}
</style>

<style scoped lang="scss">
@import "../../assets/scss/modules/form.scss";

.clear {
  clear: both;
}
.btn--secondary .btn__text {
  color: #000 !important;
}
.back-btn {
  float: left;
  margin-left: 20px;
  border-color: #b0afaf;
  color: #000 !important;
  border: 1px solid #b0afaf;
  font-size: 18px;
}
.nxt-btn {
  float: right;
  margin-right: 40px;
}

.back-btn-mobile {
  float: left;
  border-color: #b0afaf;
  color: #000 !important;
  border: 1px solid #b0afaf;
  font-size: 18px;
  min-width: 130px;
}
.nxt-btn-mobile {
  float: right;
  min-width: 130px;
}
</style>
