<template>
  <div class="upload-photo">
    <h2 class="upload-photo__title">Upload Profile Picture</h2>

    <AppDropzone
      @uploadFiles="uploadUserPhoto"
      @removeFile="removeUserPhoto"
      title="Upload Photo"
      text="Recommended size: 400x600px. Available formats: png, jpg"
    />

    <div class="upload-photo-btns">
      <AppButton
        title="Save"
        type="button"
        class="upload-photo-btns__item"
        className="primary"
        :disabled="!userPhoto"
        @click="confirmUserPhoto"
      />

      <AppButton
        title="Cancel"
        type="button"
        class="upload-photo-btns__item"
        className="secondary"
        @click.prevent="cancel"
      />
    </div>
  </div>
</template>

<script>
import AppDropzone from "@/components/ui/AppDropzone";

export default {
  name: "UploadPhotoModal",

  components: { AppDropzone },

  data() {
    return {
      userPhoto: null,
    };
  },

  methods: {
    uploadUserPhoto(files) {
      this.userPhoto = files[0];
    },

    removeUserPhoto() {
      this.userPhoto = null;
    },

    confirmUserPhoto() {
      this.$emit("confirmUploadedPhoto", this.userPhoto);
    },

    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-photo {
  &__title {
    margin-bottom: 40px;
    font-size: 24px;
  }
}

.upload-photo-btns {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 32px;

  &__item {
    min-width: initial;
    margin-right: 20px;
    flex: 1;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
