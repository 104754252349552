<template>
  <div class="letter-creation-step">
    <div class="step-heading__header" v-if="!isEditing">
      <h1 class="step-heading__title">Cover Letter</h1>
      <p class="step-heading__text">
        Provide detailed information on why you are qualified for the job you
        are applying for.
      </p>
    </div>
    <form class="creation-form" @submit.prevent="saveCoverLetter">
      <AppTextEditor
        v-if="coverLetter"
        :value="coverLetter.description"
        :isInvalid="$v.coverLetterBody.$error"
        isCoverLetter
        label="Cover Letter"
        @input="setField($event)"
      >
        <template v-slot:error>
          <p v-if="!$v.coverLetterBody.minLength">
            Cover letter must be at least
            {{ $v.coverLetterBody.$params.minLength.min }} characters
          </p>

          <p v-if="!$v.coverLetterBody.maxLength">
            The letter must be no more than
            {{ $v.coverLetterBody.$params.maxLength.max }} characters
          </p>
        </template>
      </AppTextEditor>

      <FooterButton
        v-if="!isShowBackButton"
        isSubmit="true"
        :isEditing="isEditing"
      ></FooterButton>
      <FooterButtonv2
        v-if="isShowBackButton"
        isSubmit="true"
        :isEditing="isEditing"
      ></FooterButtonv2>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { debounce } from "lodash/function";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { setIntermediateLetter } from "@/helpers/save-intermediate-data";
import { COMPONENT_NAMES } from "@/helpers/components-names";
import { eventBus } from "@/helpers/event-bus";
import {
  CREATING_COVER_LETTER_NAMES,
  ROUT_PATH,
} from "@/helpers/rout-constants";
import AppToast from "@/helpers/toast-methods";
import LetterHash from "@/helpers/cover-letter-hash";
import AppTextEditor from "@/components/ui/AppTextEditor";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import CookieHelper from "@/helpers/cookie-helper";
import FooterButton from "@/components/creation/FooterButton";
import FooterButtonv2 from "@/components/creation/FooterButtonv2";

export default {
  name: COMPONENT_NAMES.COVER_LETTER_CREATION.DETAILS_INFO,
  mixins: [mixpanelMixin],
  components: {
    AppTextEditor,
    FooterButton,
    FooterButtonv2,
  },
  data() {
    return {
      coverLetterBody: "",
      nextStep: CREATING_COVER_LETTER_NAMES.STEP_FINAL,
      prevStep: CREATING_COVER_LETTER_NAMES.STEP_1,
      isShowBackButton: false,
    };
  },
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    coverLetterBody: {
      required,
    },
  },
  computed: {
    ...mapGetters({ coverLetter: "coverLetterCreation/getCoverLetter" }),
    setFieldDebounced() {
      return debounce(async function () {
        if (this.isEditing) {
          this.$nextTick(() => {
            this.$parent.$emit("editData", this.coverLetterBody);
          });
        }

        await this.$store.dispatch("coverLetterCreation/updateCoverLetter", {
          description: this.coverLetterBody,
        });

        await setIntermediateLetter(this.coverLetter.hash);
      }, 500);
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!LetterHash.get()) {
      next(ROUT_PATH.BROWS_RESUME_TEMPLATES);
      AppToast.toastError(
        "No letter data found. Perhaps your resume has already been created. You can always view all your letters in the dashboard"
      );
      return;
    }

    next();
  },

  methods: {
    async setField(fieldData) {
      this.coverLetterBody = fieldData;
      this.setFieldDebounced();
    },

    async saveCoverLetter() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const dataToSave = { description: this.coverLetterBody, is_draft: false };

      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch(
          "coverLetterCreation/updateCoverLetter",
          dataToSave
        );
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");

      await this.$router.push({
        name: this.nextStep,
        query: { uncompletedTemplate: this.coverLetter.hash },
      });
    },

    checkValidation() {
      this.$v.$touch();

      return !this.$v.$invalid;
    },
  },

  async mounted() {
    let back_btn = CookieHelper.getFlag("back_btn", "");
    if (back_btn == "1") {
      this.isShowBackButton = true;
    }
  },

  async created() {
    await this.$store.dispatch("loader/pending");

    this.mixpanelTrack("V2-LETTERBODY");

    if (!this.isEditing) {
      await this.$store.dispatch("coverLetterCreation/getCoverLetter");
      await setIntermediateLetter(this.coverLetter.hash);
    }

    this.coverLetterBody = this.coverLetter.description;
    if (this.isEditing) {
      this.$nextTick(() => {
        this.$parent.$emit("editData", this.coverLetterBody);
      });
    }

    eventBus.$on("nextStepResumeCreation", this.saveCoverLetter);
    eventBus.$on("prevStepResumeCreation", this.goPrevStep);

    await this.$store.dispatch("loader/done");
  },

  beforeDestroy() {
    eventBus.$off("nextStepResumeCreation", this.saveCoverLetter);
    eventBus.$off("prevStepResumeCreation", this.goPrevStep);
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/modules/form.scss";
.letter-creation-step {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.step-heading__header {
  margin-bottom: 48px;
}

.detailed-info {
  max-width: 709px;
}

.creation-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

::v-deep .ql-editor {
  height: 340px !important;
  max-height: initial !important;
}
</style>
