export const COMPONENT_NAMES = {
  EDITING_PART: "EditingPart",
  RESUME_CREATION: {
    BASE_INFO: "StepBaseInfoResumeCreation",
    BASE_INFO_EDITING: "FirstStepResumeEditing",
    WORK_HISTORY: "StepWorkHistoryResumeCreation",
    EDUCATION: "StepEducationsResumeCreation",
    SKILLS: "StepSkillsResumeCreation",
    SUMMARY: "StepSummaryResumeCreation",
  },
  COVER_LETTER_CREATION: {
    BASE_INFO_EDITING: "FirstStepResumeEditing",
    DETAILS_INFO: "StepDetailedInfoLetterCreation",
  },
};

export const LOCAL_COMPONENTS_NAMES = {
  FIRST_STEP_FORM: "FirstStepForm",
  WORK_HISTORY_FORM: "WorkHistoryForm",
  EDUCATION_FORM: "EducationsForm",
  SKILL_FORM: "SkillForm",
};
