<template>
  <div class="letter-editing" v-if="coverLetter">
    <h1 class="letter-editing__header">Edit cover letter</h1>
    <EditingPart
      title="Header"
      :openedEditingPart="openedEditingPart"
      :namesValidationComponents="NAME_VALIDATION_COMPONENTS"
      @toggle="toggleEditingPart"
      @close="closeEditingPart"
    >
      <template v-slot:subText>
        Provide employers your complete name and contact details.
      </template>

      <FirstStepResumeEditing isEditing :isResume="false" />
    </EditingPart>
    <EditingPart
      title="Cover Letter"
      :openedEditingPart="openedEditingPart"
      :namesValidationComponents="NAME_VALIDATION_COMPONENTS"
      @toggle="toggleEditingPart"
      @close="closeEditingPart"
    >
      <template v-slot:subText>
        Provide detailed information on why you are qualified for the job you
        are applying for.
      </template>

      <StepDetailedInfoLetterCreation isEditing />
    </EditingPart>

    <div class="letter-editing__footer d-lg-none">
      <div class="row">
        <div class="col-12">
          <button
            class="creation-form-footer__preview"
            type="button"
            @click.prevent="openSidebar"
          >
            <AppIcon componentName="SearchIcon" />
          </button>
        </div>
        <div class="col-12">
          <AppButton
            title="Save and close"
            className="primary"
            class="creation-form-footer__submit"
            @click.prevent="saveEditing"
          />
        </div>
        <div class="col-12">
          <button
            class="creation-form-footer__prev"
            @click.prevent="cancelEditing"
          >
            Exit without saving
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditingPart from "@/views/template-editing/components/EditingPart";
import EditingHash from "@/helpers/editing-hash";
import FirstStepResumeEditing from "@/views/template-editing/resume-editing/FirstStepResumeEditing";
import StepDetailedInfoLetterCreation from "@/views/cover-letter-creation/step-2/StepDetailedInfoLetterCreation";
import { mapGetters } from "vuex";
import AppToast from "@/helpers/toast-methods";
import { eventBus } from "@/helpers/event-bus";
import { COMPONENT_NAMES } from "@/helpers/components-names";
import { USER_ACCOUNT_NAMES } from "@/helpers/rout-constants";
import { setIntermediateLetter } from "@/helpers/save-intermediate-data";

const NAME_VALIDATION_COMPONENTS = [
  COMPONENT_NAMES.COVER_LETTER_CREATION.BASE_INFO_EDITING,
  COMPONENT_NAMES.COVER_LETTER_CREATION.DETAILS_INFO,
];
export default {
  name: "CoverLetterEditing",
  components: {
    EditingPart,
    FirstStepResumeEditing,
    StepDetailedInfoLetterCreation,
  },
  data() {
    return {
      hashForClone: null,
      templateHash: null,
      openedEditingPart: null,
      NAME_VALIDATION_COMPONENTS,
    };
  },
  computed: {
    ...mapGetters({ coverLetter: "coverLetterCreation/getCoverLetter" }),
  },

  methods: {
    async getInitialData() {
      if (!this.$route?.query?.hash) {
        AppToast.toastError("No template selected for editing...");
        await this.$router.push({ name: USER_ACCOUNT_NAMES.USER_DASHBOARD });
        return;
      }

      await this.$store.dispatch("loader/pending");

      this.hashForClone = this.$route?.query?.hash;

      try {
        if (this.$route?.params?.duplicate) {
          const { hash } = await this.$store.dispatch(
            "coverLetterCreation/duplicateLetter",
            {
              hash: this.hashForClone,
              isHidden: true,
            }
          );

          EditingHash.set(hash);
          this.templateHash = hash;
        } else {
          this.templateHash = EditingHash.get();
        }
        await this.$store.dispatch(
          "coverLetterCreation/getCoverLetter",
          this.templateHash
        );

        await setIntermediateLetter(this.templateHash);
      } catch (e) {
        console.error(e);
        await this.$store.dispatch("loader/done");
        await this.$router.push({ name: USER_ACCOUNT_NAMES.USER_DASHBOARD });
      }

      await this.$store.dispatch("loader/done");
      await this.$store.dispatch("loader/done");
    },

    toggleEditingPart(editingPart) {
      if (editingPart.opened) {
        this.openedEditingPart = null;
        return;
      }

      this.openedEditingPart = editingPart.id;
    },

    closeEditingPart() {
      this.openedEditingPart = null;
    },

    openSidebar() {
      eventBus.$emit("openCreationSidebar");
    },

    async saveEditing() {
      await this.$store.dispatch("loader/pending");

      if (!this.triggerStepsValidation()) {
        AppToast.toastError("Some fields are not filled... ");
        await this.$store.dispatch("loader/done");
        return;
      }

      try {
        await this.$store.dispatch(
          "userDashboard/removeCoverLetter",
          this.hashForClone
        );

        await this.$store.dispatch(
          "coverLetterCreation/forceUpdateCoverLetter",
          {
            hash: this.templateHash,
            is_draft: false,
            is_hidden: false,
          }
        );

        await this.$store.dispatch(
          "coverLetterCreation/cleanCoverLetter",
          this.templateHash
        );

        await this.$router.push({ name: USER_ACCOUNT_NAMES.USER_DASHBOARD });
      } catch (e) {
        console.error(e);
      }

      await this.$store.dispatch("loader/done");
    },

    async cancelEditing() {
      try {
        await this.$store.dispatch(
          "userDashboard/removeCoverLetter",
          this.templateHash
        );

        await this.$store.dispatch(
          "coverLetterCreation/cleanCoverLetter",
          this.templateHash
        );

        AppToast.toastSuccess("Your changes were not saved...");
        EditingHash.clear();
        await this.$router.push({ name: USER_ACCOUNT_NAMES.USER_DASHBOARD });
      } catch (e) {
        console.error(e);
      }
    },

    triggerStepsValidation() {
      let isValidSteps = true;

      const editPartComponents = this.$children.filter((component) => {
        return component.$options.name === COMPONENT_NAMES.EDITING_PART;
      });

      editPartComponents.forEach((component) => {
        const isValid = component.validateEditingSteps();
        if (!isValid) {
          isValidSteps = false;
        }
      });

      return isValidSteps;
    },
  },

  created() {
    this.getInitialData();
    eventBus.$on("saveEditing", this.saveEditing);
    eventBus.$on("cancelEditing", this.cancelEditing);
  },

  beforeDestroy() {
    eventBus.$off("saveEditing", this.saveEditing);
    eventBus.$off("cancelEditing", this.cancelEditing);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .editing {
  margin-bottom: 30px;
}

.letter-editing {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: map_get($grid-breakpoints, md)) {
    min-height: calc(100vh - 165px);
  }

  @media (max-width: map_get($grid-breakpoints, sm)) {
    min-height: calc(100vh - 95px);
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 130%;
  }

  &__footer {
    margin-top: auto;
    text-align: center;
    min-width: 100%;
    padding: 24px 0;
  }
}
.creation-form-footer__preview {
  position: absolute;
  top: 0 !important;
  right: 9px;
  background: #fff;
  width: 70px;
  margin: 12px;
  padding: 2px;
  display: block;
  min-width: auto;
  z-index: 22;
  border: 2px solid #4874e6;
  border-radius: 30px;
}

button.creation-form-footer__preview svg {
  width: 17px;
  fill: #4874e6;
}
</style>
