<template>
  <div class="filling" :class="fillingType.class">
    <p class="filling__label">{{ fillingType.label }}</p>
    <div class="filling__icon">
      <transition name="fase">
        <AppIcon v-if="fillingDegree === 2" componentName="CheckIcon" />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditingPart",

  props: {
    fillingDegree: {
      type: [Number, String],
      default: 0,
    },
  },

  computed: {
    fillingType() {
      switch (this.fillingDegree) {
        case 1:
          return {
            class: "filling--progress",
            label: "In progress",
          };
        case 2:
          return {
            class: "filling--done",
            label: "Done",
          };
      }

      return {
        class: "",
        label: "Empty",
      };
    },
  },

  data() {
    return {
      templateHash: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.filling {
  display: inline-flex;
  align-items: center;
  margin-left: auto;

  &__label {
    font-size: 12px;
    margin-right: 8px;
    color: $light-gray;
    transition: 0.3s ease;
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid $light-gray;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    transition: all 0.3s ease;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $body-color;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    svg {
      width: 17px;
      height: 17px;
      fill: $primary;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--progress {
    .filling__label {
      color: $body-color;
    }

    .filling__icon {
      border-color: $body-color;

      &:before {
        opacity: 1;
      }
    }
  }

  &--done {
    .filling__label {
      color: $primary;
    }

    .filling__icon {
      border-color: $primary;
    }
  }
}
</style>
